import React from 'react';
import { Instagram, Youtube, Linkedin, Music } from 'lucide-react';
import Image from 'next/image';
import Link from 'next/link';

type FooterSectionProps = {
  label?: string;
  link: string;
};

const menuItems: FooterSectionProps[] = [
  {
    label: 'Home',
    link: '#',
  },
  {
    label: 'Features',
    link: '#',
  },
  {
    label: 'Benefits',
    link: '#',
  },
  {
    label: 'Tutorial',
    link: '#',
  },
  {
    label: 'Contact',
    link: '#',
  },
];
const supportItems: FooterSectionProps[] = [
  {
    label: 'Payment Methods',
    link: '/payment-methods',
  },
  {
    label: 'Privacy Policy',
    link: '/privacy-policy',
  },
  {
    label: 'Terms of Use',
    link: '/terms-of-use',
  },
  {
    label: 'Contact Us',
    link: '/contact-us',
  },
];

const FooterSection: React.FC<{}> = ({}) => {
  return (
    <footer className='bg-[#1A1A1A] text-gray-300 py-12 2xl:px-4 xl:px-16 px-16'>
      <div className='max-w-7xl mx-auto'>
        <div className='mb-[64px]'>
          <Image
            src={'/images/logo-white.png'}
            width={100}
            height={100}
            className='object-contain'
            alt='logo'
            priority
          />
        </div>

        <div className='grid grid-cols-1 md:grid-cols-4 gap-8 mb-8 pl-0 md:pl-40'>
          <div>
            <h3 className='text-lg font-semibold text-white mb-4 md:mb-12'>
              Menu
            </h3>
            <ul className='space-y-2'>
              {menuItems.map((item, index) => (
                <li key={index}>
                  <a href='#' className='hover:text-white transition-colors'>
                    {item.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h3 className='text-lg font-semibold text-white mb-4 md:mb-12'>
              Support
            </h3>
            <ul className='space-y-2'>
              {supportItems.map((item, index) => (
                <li key={index}>
                  <Link
                    href={item.link}
                    className='hover:text-white transition-colors'
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div></div>

          <div className='flex space-x-4'>
            <a
              href='#'
              className='hover:text-white transition-colors'
              aria-label='Instagram'
            >
              <Instagram className='w-6 h-6' />
            </a>
            <a
              href='#'
              className='hover:text-white transition-colors'
              aria-label='YouTube'
            >
              <Youtube className='w-6 h-6' />
            </a>
            <a
              href='#'
              className='hover:text-white transition-colors'
              aria-label='LinkedIn'
            >
              <Linkedin className='w-6 h-6' />
            </a>
            <a
              href='#'
              className='hover:text-white transition-colors'
              aria-label='TikTok'
            >
              <Music className='w-6 h-6' />
            </a>
          </div>
        </div>

        <div className='pt-8 flex justify-between items-center'>
          <p>Talent Insider</p>
          <p>&copy; {new Date().getFullYear()}. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default FooterSection;
