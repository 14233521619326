'use client';

import React from 'react';
import Link from 'next/link';
import { Button } from '@/shared/shadcn/ui/button';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import Cookies from 'js-cookie';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/shared/shadcn/ui/dropdown-menu';

type NavbarProps = {
  sectionRef?: string;
  handleClick?: (section: string) => void;
};

const Navbar: React.FC<NavbarProps> = ({
  sectionRef,
  handleClick = () => {},
}) => {
  const token = Cookies.get('sso-token');
  const pathname = usePathname();

  return (
    <div className='flex justify-center w-full inset-0'>
      <header className='xl:w-[90%] w-[80%] container px-[14px] py-[10px] md:px-[29px] md:py-[18px] flex items-center justify-between rounded-full border-[1px] fixed top-5 backdrop-blur-sm xl:mx-[164px] mx-4 z-10'>
        <Link href='/'>
          <Image
            src={'/images/logo.png'}
            width={100}
            height={100}
            className='object-contain'
            alt='logo'
            priority
          />
        </Link>

        <nav className='hidden md:flex space-x-6'>
          <span
            onClick={() => handleClick('home')}
            className={`${
              sectionRef === 'home' ? 'text-primary' : ''
            } cursor-pointer`}
          >
            Home
          </span>
          <span
            onClick={() => handleClick('features')}
            className={`${
              sectionRef === 'features' ? 'text-primary' : ''
            } cursor-pointer`}
          >
            Features
          </span>
          <span
            onClick={() => handleClick('benefits')}
            className={`${
              sectionRef === 'benefits' ? 'text-primary' : ''
            } cursor-pointer`}
          >
            Benefits
          </span>
          <span
            onClick={() => handleClick('tutorial')}
            className={`${
              sectionRef === 'tutorial' ? 'text-primary' : ''
            } cursor-pointer`}
          >
            Tutorial
          </span>
          <Link
            href='/contact'
            className={`${
              pathname === '/contact' ? 'text-primary' : ''
            } cursor-pointer`}
          >
            Contact
          </Link>
        </nav>
        {token ? (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Image
                src='/images/user.png'
                width={30}
                height={30}
                alt='profile'
                className='cursor-pointer'
                priority
              />
            </DropdownMenuTrigger>
            <DropdownMenuContent className='w-full  bg-white'>
              <DropdownMenuItem>
                <Link href='/settings'>My Profile</Link>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        ) : (
          <Link href='/auth/login' className='cursor-pointer'>
            <Button
              style={{
                borderRadius: '30px',
              }}
              className='text-white px-4 py-2'
            >
              Login
            </Button>
          </Link>
        )}
      </header>
    </div>
  );
};

export default Navbar;
